import { render, staticRenderFns } from "./StockTakingDetailsComponent.vue?vue&type=template&id=147edd70&scoped=true"
import script from "./StockTakingDetailsComponent.vue?vue&type=script&lang=js"
export * from "./StockTakingDetailsComponent.vue?vue&type=script&lang=js"


/* normalize component */
import normalizer from "!../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "147edd70",
  null
  
)

export default component.exports