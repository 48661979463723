<template>
    <v-container class="pa-0" fluid>
        <page-title
                :heading="$t('erp.lang_ware_inventur') "
                :icon="icon"
                :subheading="$t('erp.lang_ware_inventur')"
                show-previous-button
                show-add-button
                :url-add="'/erp/storage/stockTaking/' +  this.$route.params.id + '/create'"
        ></page-title>
        <div class="app-main__inner">
           <stock-taking-details-component />
        </div>
    </v-container>
</template>

<script>
    //components
    import PageTitle from "../../../../Layout/Components/PageTitle.vue";
    import StockTakingDetailsComponent
        from "../../../../components/erp/storage/stocktaking/StockTakingDetailsComponent";

    export default {
        components: {
            StockTakingDetailsComponent,
            PageTitle,

        },
        data: () => ({
            icon: "pe-7s-box1 icon-gradient bg-tempting-azure"
        })
    };
</script>

<style>
</style>