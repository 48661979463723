<template>
  <v-container fluid>
    <v-card elevation="5">
      <v-card-title>
        {{ $t('erp.lang_stockTakingDetails') }}
      </v-card-title>

      <v-card-text>
        <Datatable
            :api-endpoint="ENDPOINTS.DATATABLES.ERP.STOCKTAKING.DETAILS"
            :data="this.params"
            :datatable-headers="this.datatableHeaders"
            :elevation="0"
            :excel-columns="excelColumns"
            :v-show="true"
            excel-file-name="stocktaking"
            ref="stocktakingdetails"
            show-select-checkbox
            show-delete-buttons
            @deleteEntry="deleteEntry"
            show-edit-buttons
            @editEntry="editEntry"
        />
      </v-card-text>

    </v-card>
    <v-dialog v-model="dialog" persistent max-width="100%" width="600px" >
      <v-card>
        <v-card-title>
          {{ $t('erp.lang_editStockTakingDetails') }}
        </v-card-title>
        <v-card-text>
          <v-text-field style="margin-top: 25px;"
                        :data-layout="KEYBOARD.KEYSETS.NORMAL"
                        :disabled="this.selected == null"
                        @click:append="++qt"
                        @click:prepend-inner="--qt"
                        @focus="showTouchKeyboard"
                        @keypress="isNumber($event)"
                        :label="$t('erp.newStock')"
                        append-icon="add"
                        class="text-center mx-auto"
                        id="qt"
                        outlined
                        prepend-inner-icon="remove"
                        v-model.number="qt"
          />
        </v-card-text>
        <v-card-actions>
          <v-btn
              :disabled="loading"
              :loading="loading"
              @click="dialog=false"
              color="error"
              depressed
          >
            {{ $t('generic.lang_cancel') }}
          </v-btn>
          <v-spacer/>
          <v-btn
              :disabled="loading"
              :loading="loading"
              @click="update"
              color="success"
              depressed
          >
            {{ $t('generic.lang_save') }}
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </v-container>
</template>

<script>


//components
//configs
import {ENDPOINTS} from "../../../../config";
import Datatable from "../../../datatable/Datatable";
import mixin from "../../../../mixins/KeyboardMixIns";
import {Events} from "../../../../plugins/events";

export default {
  name: "StockTakingDetailsComponent",
  components: {Datatable},
  data: () => {
    return {
      ENDPOINTS,
      dialog:false,
      loading:false,
      selected:null,
      qt:0,
    }
  },
  mixins:[mixin],
  computed: {
    params() {
      return {
        stockTakingID: this.$route.params.id
      }
    },
    datatableHeaders: function () {
      return [
        {
          text: this.$t("erp.lang_ware_create_id"),
          value: "id",
          sortable: false
        },
        {
          text: this.$t("erp.lang_posItemName"),
          value: "item_name",
          sortable: true
        },
        {
          text: this.$t("erp.oldStock"),
          value: "oldStock",
          sortable: false
        },
        {
          text: this.$t("erp.newStock"),
          value: "newStock",
          sortable: false
        },
        {
          text: this.$t('erp.lang_inventoryDifference'),
          value: "stockTakingDiff",

        },
        {
          text: this.$t("generic.lang_date"),
          value: "date_of_registration",
          sortable: false
        },
        {
          text: "wareid",
          value: "wareid",
          sortable: false,
          hide:true,
        },
      ];
    },
    excelColumns: function () {
      return [
        {
          label: this.$t("erp.lang_ware_create_id"),
          field: "eanField"
        },
        {
          label: this.$t("erp.lang_posItemName"),
          field: "item_name"
        },
        {
          label: this.$t("erp.oldStock"),
          field: "oldStock"
        },
        {
          label: this.$t("erp.newStock"),
          field: "newStock"
        },
        {
          label: this.$t('erp.lang_inventoryDifference'),
          field: "stockTakingDiff"
        },
        {
          label: this.$t("generic.lang_date"),
          field: "date_of_registration"
        }
      ];
    }
  },
  methods: {
    deleteEntry(entry){
      let self = this;
      this.$swal({
        title: this.$t('generic.lang_delete'),
        text: this.$t('generic.lang_deleteConfirmationMSG')+' '+this.$t("erp.lang_item"),
        icon: "warning",
        cancelButtonText: this.$t('generic.lang_cancel'),
        confirmButtonText: this.$t('generic.lang_delete'),
        showCancelButton: true,
        showLoaderOnConfirm: true,
        preConfirm: () => {
          this.axios.post(ENDPOINTS.ERP.STOCKTAKING.DETAILS.DELETE, {
            stockTakingID:this.$route.params.id,
            wareID: entry.wareid,
          }).then((res) => {
            if (res.data.status === 'SUCCESS') {
              Events.$emit("showSnackbar", {
                message: this.$t('generic.lang_success'),
                color: "success"
              });
              self.$refs.stocktakingdetails.getDataFromApi();
              self.$refs.stocktakingdetails.resetSelectedRows();
            } else {
              Events.$emit("showSnackbar", {
                message: this.$t('generic.lang_errorOccurred')+' '+res.data.msg,
                color: "error"
              });
            }
          });
        },
        allowOutsideClick: () => !this.$swal.isLoading
      });
    },
    editEntry(entry){
      this.selected=entry;
      this.qt=parseInt(Number(entry.newStock));
      this.dialog=true;
    },
    isNumber: function (evt) { // to accept only numbers
      evt = (evt) ? evt : window.event;
      var charCode = (evt.which) ? evt.which : evt.keyCode;
      if ((charCode > 31 && (charCode < 48 || charCode > 57)) && charCode !== 46) {
        evt.preventDefault();
      } else {
        return true;
      }
    },
    update() {
      this.loading = true;
      this.axios.post(ENDPOINTS.ERP.STOCKTAKING.UPDATE_QT,
          {
            stockTakingID: this.$route.params.id,
            wareID: this.selected.wareid,
            stockItemTakQTY: this.qt,
          }).then((res) => {

        if (res.data.status === "SUCCESS") {
          Events.$emit("showSnackbar", {
            color: "success",
            message: this.$t('erp.stockTakingRecorded')
          });
          this.selected = null;
          this.qt = 0;
          this.dialog = false;
          this.$refs.stocktakingdetails.getDataFromApi();
        }


      }).catch((err) => {
        Events.$emit("showSnackbar", {
          color: "error",
          message: err.message
        });
      }).finally(() => {

        this.loading = false;
      });
    },
  }
}
</script>

<style scoped>

</style>